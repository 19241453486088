<template>
  <div class="WorkDescription">
    <span class="Title">{{ mixWB('WORK_DESCRIPTION_AND_INDICATIVE_DISPOSAL') }}</span>
    <template
      v-for="(text, index) in workDescriptionTexts">
      <span
        :key="text"
        v-if="text === '-material-header-'"
        class="SubTitle"
        v-html="mixWB('THE_MATERIAL_X', [groupedType.getGroupedTypeTitle()])"/>
      <span
        :key="text"
        v-else-if="text === '-coating-header-'"
        class="SubTitle"
        v-html="mixWB('COATING_X', [getCoatingType(groupedType)])"/>
      <p
        :key="text"
        v-else-if="text === '-need-to-be-cleaned-overlying-'"
        class="NoBottomSpace"
        v-html="mixWB('MUST_BE_CLEANED_TO_CLEAN_OVERLYING_MATERIAL')"/>
      <p
        :key="text"
        v-else-if="text === '-need-to-be-cleaned-underlying-'"
        class="NoBottomSpace"
        v-html="mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL')"/>
      <p
        :key="text"
        v-else-if="text === '-multi-type-asbestos-obs-text-'"
        v-html="mixWB('MULTI_TYPE_ASBESTOS_OBS_TEXT')"/>
      <p
        :key="text"
        v-else-if="text === '-tile-adhesive-header-'"
        class="SubTitle"
        v-html="mixWB('TILE_ADHESIVE')"/>
      <p
        :key="text"
        v-else-if="text === '-tiles-and-joints-header'"
        class="SubTitle"
        v-html="mixWB('TILES_AND_JOINTS')"/>
      <p
        v-else
        :key="index + text"
        v-html="text"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'WorkDescription',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
    workDescriptionTexts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCoatingType(groupedType) {
      const lastType = groupedType.types[groupedType.types.length - 1]
      return lastType.getCoatingText()
    },
  },
}
</script>

<style lang="stylus" scoped>
  .WorkDescription
    .Title
      text-transform uppercase
      padding-bottom 10px
    .SubTitle
      text-decoration underline
      padding-bottom 0
    p
      &:last-child
        padding-bottom 0
      &.NoBottomSpace
        padding-bottom 0
</style>
