<template>
  <div
    class="AnalysisResultsPage">
    <PageTag
      v-if="isFirstPage"
      :pageName="mixWB('ANALYSIS_RESULTS_OVERVIEW')" />

    <PageLoading v-if="hasSamples && !pageSamples.length" />

    <div
      class="InnerPage js-inner-page">

      <div
        ref="Content"
        class="Content">
        <!-- First page only -->
        <template v-if="isFirstPage">
          <h1
            v-if="pdfType === 'tender'"
            class="js-headline"
            id="analysis-overview">6. {{ mixWB('CONTAMINATED_AND_OR_HAZARDOUS_MATERIALS') }}</h1>
          <h2
            v-if="pdfType === 'report'"
            class="SubTitle">6.2 {{ mixWB('RISK_MATERIALS_AND_ANALYSIS_RESULTS') }}</h2>
        </template>

        <!-- Other pages only -->
        <h2
          v-if="!isFirstPage && pdfType === 'report'"
          class="SubTitle">{{ mixWB('CONTINUED') }}...</h2>

        <template v-if="hasSamples">
          <Table
            :samples="pageSamples" />
        </template>

        <template v-else>
          <p>{{ mixWB('NO_ANALYSIS_MADE_TEXT') }}</p>
        </template>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import Table from '@/components/PDF/AnalysisResults/Table.vue'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'AnalysisResultsPage',
  props: {
    samples: {
      type: Array,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    isFirstPage: {
      type: Boolean,
      required: true,
    },
    pdfType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pageMaxHeight: 975,
      startSamplesPerPage: 15,
      pageSamples: [],
    }
  },
  computed: {
    hasSamples() {
      return !!this.samples.length
    },
  },
  methods: {
    onPageIsFull(nextSampleIndex) {
      this.pageSamples.pop()
      this.$emit('page-is-full', nextSampleIndex - 1)
    },
    fillUpPage() {
      const nextSampleIndex = this.startIndex + this.pageSamples.length

      // Check page height
      const { height } = this.$refs.Content.getBoundingClientRect()
      if (height > this.pageMaxHeight) {
        this.onPageIsFull(nextSampleIndex)
        return
      }

      const nextSample = this.samples[nextSampleIndex]

      // Check if there are more to add
      if (!nextSample) {
        this.$emit('all-samples-added')
        return
      }
      // Add next sample to page
      if (!this.pageSamples.length) {
        this.pageSamples = this.samples.slice(
          this.startIndex, this.startIndex + this.startSamplesPerPage,
        )
      }
      else {
        this.pageSamples.push(nextSample)
      }

      // Do it again
      requestAnimationFrame(() => {
        this.fillUpPage()
      })
    },
  },
  components: {
    PageTag,
    PageLoading,
    Table,
    Footer,
  },
  mounted() {
    this.fillUpPage()
  },
}
</script>

<style lang="stylus" scoped>
  .AnalysisResultsPage
    page()

  .InnerPage
    innerPageReport()
</style>
