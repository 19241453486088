<template>
  <div
    :class="{ LastIsFull: typesToShow.lastIsFull }"
    class="CategoryTypeMetaData">
    <!-- Units -->
    <div
      v-if="typesToShow.items.includes('units')"
      class="Item">
      <div class="IconWrap">
        <HouseIcon />
      </div>
      <span>{{ groupedType.types[0].getUnitsList() }}</span>
    </div>

    <!-- Buildings -->
    <div
      v-if="typesToShow.items.includes('buildings')"
      class="Item">
      <div class="IconWrap">
        <HouseIcon />
      </div>
      <span>{{ mixWB('BUILDING') }} {{ buildingNumbers }}</span>
    </div>

    <!-- Places -->
    <div
      v-if="typesToShow.items.includes('places')"
      class="Item">
      <div class="IconWrap Smaller">
        <RoomsIcon />
      </div>
      <span>{{ getGroupedTypePlaces() }}</span>
    </div>

    <!-- Amount -->
    <div
      v-if="typesToShow.items.includes('amount')"
      class="Item">
      <div class="IconWrap">
        <AmountIcon />
      </div>
      <span>{{ getGroupedTypeAmount() }}</span>
    </div>

    <!-- Notes -->
    <div
      v-if="typesToShow.items.includes('note')"
      class="Item">
      <div class="IconWrap Smaller">
        <NoteIcon />
      </div>
      <span>{{ getGroupedTypeDescription() }}</span>
    </div>

    <!-- Potential resale -->
    <div
      v-if="typesToShow.items.includes('potential-resale')"
      class="Item">
      <div class="IconWrap">
        <CoinsIcon />
      </div>
      <span>{{ mixWB('HAS_POTENTIAL_FOR_RESALE') }}</span>
    </div>
  </div>
</template>

<script>
import HouseIcon from '@/assets/svg/house.svg?inline'
import RoomsIcon from '@/assets/svg/categories/plinth.svg?inline'
import AmountIcon from '@/assets/svg/amount.svg?inline'
import NoteIcon from '@/assets/svg/notepad.svg?inline'
import CoinsIcon from '@/assets/svg/coins.svg?inline'
import { mapGetters } from 'vuex'
import { union } from 'lodash-es'

export default {
  name: 'CategoryTypeMetaData',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'places',
      'selectedBuildings',
      'selectedUnits',
    ]),
    buildingNumbers() {
      const buildings = this.selectedBuildings.filter(
        (x) => this.type.buildings.includes(x.buildingID),
      )
      return buildings.reduce((prev, building, index) => {
        if (index === 0) {
          prev += building.data.Bygningsnr
          return prev
        }

        if (index === buildings.length - 1) {
          prev = `${ prev } ${ this.mixWB('AND') } ${ building.data.Bygningsnr }`
          return prev
        }

        prev = `${ prev }, ${ building.data.Bygningsnr }`
        return prev
      }, '')
    },
    typesToShow() {
      const data = {
        items: [],
        lastIsFull: false,
      }
      if (this.groupedType.types[0].getUnitsList()) {
        data.items.push('units')
      }
      if (this.buildingNumbers) {
        data.items.push('buildings')
      }
      if (this.getGroupedTypePlaces()) {
        data.items.push('places')
      }
      if (this.getGroupedTypeAmount()) {
        data.items.push('amount')
      }
      if (this.getGroupedTypeDescription()) {
        data.items.push('note')
      }
      if (this.getPotentialResaleStatus()) {
        data.items.push('potential-resale')
      }

      if (data.items.length % 2 === 1) {
        data.lastIsFull = true
      }

      return data
    },
  },
  methods: {
    getGroupedTypePlaces() {
      let combinedPlaces = []
      this.groupedType.types.forEach((type) => {
        if (this.groupedType.duplicateTypeIDs.includes(type.id)) {
          return
        }
        type.places.placeIDs.forEach((placeID) => {
          combinedPlaces = union(
            combinedPlaces,
            [this.mixWB(this.places[placeID].wordbook).toLowerCase()],
          )
        })
        type.places.otherPlaces.forEach((otherPlace) => {
          combinedPlaces = union(combinedPlaces, [otherPlace.toLowerCase()])
        })
      })

      combinedPlaces.sort()

      if (combinedPlaces.length) {
        combinedPlaces[0] = combinedPlaces[0].charAt(0).toUpperCase() + combinedPlaces[0].slice(1)
      }

      return combinedPlaces.join(', ')
    },
    getGroupedTypeAmount() {
      const firstTypeWith = this.groupedType.types.find(
        (x) => !x.amount.isSkipped && !this.groupedType.duplicateTypeIDs.includes(x.id),
      )

      if (!firstTypeWith) {
        return false
      }

      return firstTypeWith.getAmountText()
    },
    getGroupedTypeDescription() {
      const descriptions = []
      this.groupedType.types.forEach((type) => {
        if (type.description) {
          let text = type.description
          if (text.slice(-1) !== '.') {
            text += '.'
          }
          descriptions.push(text)
        }
      })

      return descriptions.join(' ')
    },
    getPotentialResaleStatus() {
      if (this.groupedType.types.length > 1) {
        return false
      }
      return this.groupedType.types[0].resale === 'potential'
    },
  },
  components: {
    HouseIcon,
    RoomsIcon,
    AmountIcon,
    NoteIcon,
    CoinsIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeMetaData
    display flex
    flex-wrap wrap
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    .Item
      display flex
      align-items center
      min-height 40px
      padding-left 20px
      width 50%
      border-bottom 1px solid $color_grey_lighter
      span
        line-height 1.3
      &:nth-child(even)
        border-left 1px solid $color_grey_lighter
      .IconWrap
        flex-shrink 0
        box(25px)
        margin-right 10px
        &.Smaller
          padding 2px
    &.LastIsFull
      .Item
        &:last-child
          width 100%
          justify-content center
</style>
