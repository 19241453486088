<template>
  <div class="CategoryTypeExtraPage">
    <div class="ContinuedText">
      <span>{{ mixWB('CONTINUATION_OF_PREVIOUS_PAGE') }}</span>
    </div>

    <!-- Test results -->
    <div
      v-if="filteredGroupedTypeSamples.length > 3"
      class="SampleTableWrap">
      <SampleTable
        :samples="filteredGroupedTypeSamples"
        :pageNumber="2" />
    </div>

    <!-- Work descriptions -->
    <div
      class="WorkDescriptionWrap">
      <WorkDescription
        :groupedType="groupedType"
        :workDescriptionTexts="workDescriptionTexts" />
    </div>

    <!-- Back to overview -->
    <BackOptions :groupedType="groupedType" />
  </div>
</template>

<script>
import SampleTable from '@/components/PDF/CategoriesAndTypes/SampleTable.vue'
import WorkDescription from '@/components/PDF/CategoriesAndTypes/WorkDescription.vue'
import BackOptions from '@/components/PDF/CategoriesAndTypes/BackOptions.vue'

export default {
  name: 'CategoryTypeExtraPage',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
    previousGroupedTypes: {
      type: Array,
      required: true,
    },
    nextGroupedTypes: {
      type: Array,
      required: true,
    },
    filteredGroupedTypeSamples: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      workDescriptionTexts: [],
    }
  },
  computed: {
    groupedTypeResult() {
      return this.groupedType.groupedTypeResult
    },
  },
  methods: {
    getFinalResultsOnLoad() {
      const {
        workDescriptionTexts,
      } = this.groupedTypeResult.getAllResults({
        groupedType: this.groupedType,
        previousGroupedTypes: this.previousGroupedTypes,
        nextGroupedTypes: this.nextGroupedTypes,
        groupedTypeTitle: this.groupedType.getGroupedTypeTitle(),
      })

      this.workDescriptionTexts = workDescriptionTexts
    },
  },
  components: {
    SampleTable,
    WorkDescription,
    BackOptions,
  },
  created() {
    this.getFinalResultsOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeExtraPage
    position relative
    display block
    border 1px solid $color_grey_dark

  .ContinuedText
    padding 10px 20px
    border-bottom 1px solid $color_grey_lighter

  .WorkDescriptionWrap
    padding 15px 20px
    border-bottom 1px solid $color_grey_lighter
</style>
