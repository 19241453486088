<template>
  <div class="SampleTable">
    <div class="Column">
      <span class="Header">{{ mixWB('SAMPLES_2') }}</span>
      <span
        v-for="item in testResultsForSingleSample"
        :key="item.id">
        {{ mixWB(item.wordbook) }}
      </span>
    </div>
    <SampleTableColumn
      v-for="sample in getSamplesToShow()"
      :key="sample.id"
      :sample="sample"
      :hideImage="pageNumber === 1 && samples.length === 1" />

    <template v-if="pageNumber === 1 && samples.length === 1">
      <!-- Sample -->
      <div
        class="SampleImages"
        v-if="samples[0].sampleNumber">
        <span class="Title">{{ mixWB('IMAGES_OF_X', [`P${ samples[0].sampleNumber }`]) }}</span>
        <div class="ImageList">
          <div class="Image">
            <MultiImage
              :image="samples[0].images.close" />
          </div>
          <div class="Image">
            <MultiImage
              :image="samples[0].images.away" />
          </div>
        </div>
      </div>

      <!-- Equivalent -->
      <div
        v-if="samples[0].equivalentNumber"
        class="Equivalent">
        <span>{{ mixWB('CORRESPONDING_SAMPLE') }} P{{ samples[0].equivalentSampleNumber }}</span>
        <span v-if="samples[0].otherScreeningID">
          {{ mixWB('TAKEN_FROM_OTHER_PROJECT_X', [samples[0].getRelatedScreeningCaseNumber()]) }}
        </span>
      </div>
    </template>

  </div>
</template>

<script>
import { testResultsForSingleSample } from '@/globals/javascript/_util/test-results'
import MultiImage from '@/components/Images/MultiImage.vue'
import SampleTableColumn from './SampleTableColumn.vue'

export default {
  name: 'SampleTable',
  props: {
    samples: {
      type: Array,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      testResultsForSingleSample,
    }
  },
  methods: {
    getSamplesToShow() {
      if (this.pageNumber === 1) {
        return this.samples.slice(0, 3)
      }
      if (this.pageNumber === 2) {
        return this.samples.slice(3, 6)
      }
      return []
    },
  },
  components: {
    SampleTableColumn,
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .SampleTable
    display flex
    border-bottom 1px solid $color_grey_lighter

  .Column
    width 25%
    border-right 1px solid $color_grey_lighter
    span
      padding 2.5px 10px
      font-size 0.75rem
      background-color #fff
      border-bottom 1px solid #fff
      &:nth-child(even)
        background-color $color_grey_lightest
        border-bottom 1px solid $color_grey_lightest
    .Header
      height 40px
      display flex
      align-items center
      font-size 1rem
      background-color transparent
      border-bottom 1px solid transparent

  .SampleImages
    flex-grow 2
    flex-center-children-column()
    justify-content center
    .Title
      padding-bottom 10px
      text-transform uppercase
      text-align center
    .ImageList
      display flex
      justify-content center
      width 100%
      .Image
        width calc(50% - 30px)
        margin 0px 7.5px 10px

  .Equivalent
    flex-grow 2
    flex-center-children-column()
    justify-content center
    span
      padding 0px 20px
      text-transform uppercase
      text-align center
</style>
