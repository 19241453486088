<template>
  <div class="CategoryOverviewPage">
    <PageTag
      v-if="isFirstPage"
      :pageName="mixWB('CATEGORIES_AND_TYPES')" />

    <div class="InnerPage js-inner-page">
      <div ref="Content">
        <!-- Tender start text -->
        <template v-if="isFirstPage && isTender && categoryIndex === 1">
          <h1
            class="js-headline"
            id="category-list">7. {{ mixWB('ASSESSMENT_AND_RECOMMENDATION') }}</h1>
          <p>
            Herunder kan ses en detaljeret visning af hvert forurenede
            og/eller farlige materiale, samt eventuelle emner til gensalg.
          </p>
          <br/>
        </template>

        <!-- Category title -->
        <span
          v-if="isFirstPage"
          :id="category.id"
          class="CategoryTitle js-category-title">
          {{ categoryIndex }}. {{ mixWB(category.wordbook) }}
        </span>

        <!-- Category type list overview -->
        <CategoryOverview
          :category="category"
          :categoryIndex="categoryIndex"
          :groupedTypes="pageItems" />

        <!-- Show category type if there is room -->
        <CategoryType
          v-if="isLastPage && !splitIntoTwoPages"
          ref="CategoryType"
          :groupedType="groupedType"
          :previousGroupedTypes="previousGroupedTypes"
          :nextGroupedTypes="nextGroupedTypes"
          :filteredGroupedTypeSamples="filteredGroupedTypeSamples"
          :categoryIndex="categoryIndex"
          :hasNoRoomForWorkDescription="hasNoRoomForWorkDescription" />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import CategoryOverview from '@/components/PDF/CategoriesAndTypes/CategoryOverview.vue'
import CategoryType from '@/components/PDF/CategoriesAndTypes/CategoryType.vue'
import Footer from '@/components/PDF/Footer.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryOverviewPage',
  props: {
    isFirstPage: {
      type: Boolean,
      required: true,
    },
    isLastPage: {
      type: Boolean,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
    category: {
      type: Object,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    groupedType: {
      type: Object,
      required: true,
    },
    previousGroupedTypes: {
      type: Array,
      required: true,
    },
    nextGroupedTypes: {
      type: Array,
      required: true,
    },
    filteredGroupedTypeSamples: {
      type: Array,
      required: true,
    },
    hasNoRoomForWorkDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
    splitIntoTwoPages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pageMaxHeight: 975,
      startItemsPerPage: 15,
      pageItems: [],
    }
  },
  computed: {
    ...mapGetters([
      'categoriesWithGroupedTypes',
    ]),
    groupedTypes() {
      return this.categoriesWithGroupedTypes.find(
        (x) => x.category.id === this.category.id,
      ).groupedTypes
    },
  },
  watch: {
    isLastPage(to) {
      if (to) {
        requestAnimationFrame(() => {
          this.checkPageHeight()
        })
      }
    },
  },
  methods: {
    onPageIsFull(nextGroupedTypeIndex) {
      this.pageItems.pop()
      this.$emit('page-is-full', nextGroupedTypeIndex - 1)
    },
    fillUpPage() {
      const nextGroupedTypeIndex = this.startIndex + this.pageItems.length

      // Check page height
      const { height } = this.$refs.Content.getBoundingClientRect()
      if (height > this.pageMaxHeight) {
        this.onPageIsFull(nextGroupedTypeIndex)
        return
      }

      const nextGroupedType = this.groupedTypes[nextGroupedTypeIndex]

      // Check if there are more to add
      if (!nextGroupedType) {
        this.$emit('last-item-added')
        return
      }

      // Add next item to page
      // - Add bulk when empty
      if (!this.pageItems.length) {
        this.pageItems = this.groupedTypes.slice(
          this.startIndex, this.startIndex + this.startItemsPerPage,
        )
      }
      // - Add single
      else {
        this.pageItems.push(nextGroupedType)
      }

      // Do it again
      requestAnimationFrame(() => {
        this.fillUpPage()
      })
    },
    checkPageHeight() {
      if (!this.$refs.Content) {
        setTimeout(() => {
          this.checkPageHeight()
        }, 100)
        return
      }

      // Check if page has category overview and is too high
      const { height } = this.$refs.Content.getBoundingClientRect()
      if (height <= this.pageMaxHeight) {
        return
      }
      this.$emit('split-into-two-pages')
    },
  },
  components: {
    PageTag,
    CategoryOverview,
    Footer,
    CategoryType,
  },
  mounted() {
    const waitForContent = setInterval(() => {
      if (!this.$refs.Content) {
        return
      }

      clearInterval(waitForContent)
      this.fillUpPage()
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryOverviewPage
    page()

  .InnerPage
    innerPageReport()

  .CategoryTitle
    text-transform uppercase
    font-weight bold
    margin-bottom 5px
    font-size 1.125rem
</style>
