<template>
  <div
    :id="groupedType.id"
    ref="CategoryType"
    class="CategoryType">
    <!-- Top -->
    <div class="Top">
      <!-- Title -->
      <div class="TitleWrap">
        <span class="Category">{{ mixWB(category.wordbook) }}</span>
        <span class="Type">{{ groupedType.getGroupedTypeTitle({
          withDetails: true,
          withIndex: true
        }) }}</span>
      </div>

      <!-- Results -->
      <div
        v-if="results.length"
        class="ResultsWrap"
        :class="{ MultipleResults: results.length > 1 }">
        <div
          v-for="(result, index) in results"
          :key="result.kindOfResult || 'main'"
          class="Result"
          :class="result.className">
          <span class="Conclusion">{{ result.resultText }}</span>
          <span
            v-if="index === 0 && results.length > 1"
            class="KindOfResult">
            ({{ mixWB('THE_MATERIAL') }})
          </span>
          <span
            v-if="result.kindOfResult === 'coating'"
            class="KindOfResult">
            ({{ mixWB('COATING') }})
          </span>
        </div>
      </div>
    </div>

    <!-- Inter connection -->
    <div
      v-if="previousGroupedTypes.length || nextGroupedTypes.length"
      class="InterConnection">
      <div class="IconWrap">
        <ChainIcon />
      </div>
      <div class="Texts">
        <a
          :href="'#' + item.id"
          v-for="item in getLeftConnectionTexts()"
          :key="item.id"
          v-html="item.text"
          @click.prevent="onConnectionClick(item.id)"/>
        <a
          :href="'#' + item.id"
          v-for="item in getRightConnectionTexts()"
          :key="item.id"
          v-html="item.text"
          @click.prevent="onConnectionClick(item.id)"/>
      </div>
    </div>

    <!-- Stuck together text -->
    <div
      v-if="groupedType.types.length > 1"
      class="StuckTogether">
      <div class="IconWrap">
        <LockIcon />
      </div>
      <span>{{ getStuckTogetherText() }}</span>
    </div>

    <!-- Images -->
    <div class="ImageList">
      <template
        v-for="(image, index) in getImageList()">
        <div
          v-if="index < 3"
          :key="image.base.path"
          class="Image" >
          <MultiImage
            :image="image" />
        </div>
      </template>
    </div>

    <!-- Data about the type -->
    <CategoryTypeMetaData :groupedType="groupedType" />

    <!-- Explanations -->
    <div
      v-if="explanationTexts.length"
      class="ExplanationWrap">
      <p
        v-for="text in explanationTexts"
        :key="text">{{ text }}</p>
    </div>

    <!-- Test results -->
    <div
      v-if="filteredGroupedTypeSamples.length"
      class="SampleTableWrap">
      <SampleTable
        :samples="filteredGroupedTypeSamples"
        :pageNumber="1" />
    </div>

    <!-- Work descriptions -->
    <div
      v-if="!hasNoRoomForWorkDescription
        && workDescriptionTexts.length"
      class="WorkDescriptionWrap">
      <WorkDescription
        :groupedType="groupedType"
        :workDescriptionTexts="workDescriptionTexts" />
    </div>

    <!-- Continued on next page -->
    <div
      v-if="hasNoRoomForWorkDescription && workDescriptionTexts.length"
      class="MoreOnNextPage">
      <span v-if="filteredGroupedTypeSamples.length > 3">
        {{ mixWB('SEE_REMAINING_TESTS_AND_WORK_DESCRIPTION_ON_THE_NEXT_PAGE') }}
      </span>
      <span v-else>{{ mixWB('SEE_WORK_DESCRIPTION_ON_THE_NEXT_PAGE') }}</span>
    </div>

    <!-- Back to overview -->
    <BackOptions
      v-if="!hasNoRoomForWorkDescription"
      :groupedType="groupedType" />
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import CategoryTypeMetaData from '@/components/PDF/CategoriesAndTypes/CategoryTypeMetaData.vue'
import SampleTable from '@/components/PDF/CategoriesAndTypes/SampleTable.vue'
import WorkDescription from '@/components/PDF/CategoriesAndTypes/WorkDescription.vue'
import BackOptions from '@/components/PDF/CategoriesAndTypes/BackOptions.vue'
import ChainIcon from '@/assets/svg/chain.svg?inline'
import LockIcon from '@/assets/svg/lock.svg?inline'
import { mapGetters } from 'vuex'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'CategoryType',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
    previousGroupedTypes: {
      type: Array,
      required: true,
    },
    nextGroupedTypes: {
      type: Array,
      required: true,
    },
    filteredGroupedTypeSamples: {
      type: Array,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: false,
      default: -1,
    },
    hasNoRoomForWorkDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      results: [],
      explanationTexts: [],
      workDescriptionTexts: [],
    }
  },
  computed: {
    ...mapGetters([
      'categories',
    ]),
    groupedTypeResult() {
      return this.groupedType.groupedTypeResult
    },
    category() {
      return this.categories.find((x) => x.id === this.groupedType.categoryID)
    },
  },
  methods: {
    getLeftConnectionTexts() {
      return this.previousGroupedTypes.map((previousGroupedType) => ({
        id: previousGroupedType.id,
        text: `${
          this.mixWB('SITS_ON_X', [
            previousGroupedType.getGroupedTypeTitle({
              withDetails: true,
              withIndex: true,
            }),
          ])
        }`,
      }))
    },
    getRightConnectionTexts() {
      return this.nextGroupedTypes.map((nextGroupedType) => ({
        id: nextGroupedType.id,
        text: `${
          this.mixWB('ON_THIS_SITS_X', [
            nextGroupedType.getGroupedTypeTitle({
              withDetails: true,
              withIndex: true,
            }),
          ])
        }`,
      }))
    },
    getImageList() {
      if (this.groupedType.types.length === 1) {
        return this.groupedType.types[0].images
      }

      const images = []
      this.groupedType.types.forEach((type) => {
        images.push(type.images[0])
      })
      return images
    },
    getStuckTogetherText() {
      const combinedTitle = this.groupedType.getGroupedTypeTitle()
      return this.mixWB('X_CANT_BE_SPLIT_TEXT', [combinedTitle])
    },
    getFinalResultsOnLoad() {
      const {
        results,
        explanationTexts,
        workDescriptionTexts,
      } = this.groupedTypeResult.getAllResults({
        groupedType: this.groupedType,
        previousGroupedTypes: this.previousGroupedTypes,
        nextGroupedTypes: this.nextGroupedTypes,
        groupedTypeTitle: this.groupedType.getGroupedTypeTitle(),
        lastTypeTitleWithCoating: this.groupedType.getGroupedTypeTitle({
          withDetails: true,
          lastTypeOnly: true,
        }),
      })

      this.results = results
      this.explanationTexts = explanationTexts
      this.workDescriptionTexts = workDescriptionTexts
    },
    onConnectionClick(id) {
      const element = document.getElementById(id)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
  components: {
    ChainIcon,
    LockIcon,
    MultiImage,
    CategoryTypeMetaData,
    SampleTable,
    WorkDescription,
    BackOptions,
  },
  created() {
    this.getFinalResultsOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryType
    position relative
    display block
    border 1px solid $color_grey_dark
    span
      display block

  .Top
    display flex
    background-color $color_grey_dark
    .TitleWrap
      flex-grow 2
      padding 5px 15px
      display flex
      justify-content center
      flex-direction column
      span
        color #fff
      .Category
        font-size 0.75rem
      .Type
        font-size 1.125rem
  .ResultsWrap
    display flex
    align-items center
    justify-content center
    .Result
      flex-center-children-column()
      justify-content center
      width 200px
      height 100%
      background-color $color_grey_lightest
      padding 0 10px
      .Conclusion
        font-weight bold
        text-align center
        font-size 0.875rem
        line-height 1rem
      &.Clean
        background-color $color_report_green
      &.Contaminated
        background-color $color_report_yellow
      &.Hazardous
        background-color $color_report_red
    &.MultipleResults
      .Result
        width 150px

  .InterConnection
    display flex
    align-items center
    min-height 40px
    padding 5px 10px
    background-color $color_grey_lightest
    border-bottom 1px solid $color_grey_lighter
    .IconWrap
      box(22px)
      margin-right 10px
      flex-shrink 0
    .Texts
      flex-grow 2
      a
        truncated()
        width 80%
        line-height 1.5
        text-decoration underline
        display block

  .StuckTogether
    display flex
    align-items center
    height 40px
    padding 5px 10px
    background-color $color_grey_lightest
    border-bottom 1px solid $color_grey_lighter
    .IconWrap
      box(22px)
      margin-right 10px
      flex-shrink 0
    span
      line-height 1.25

  .ImageList
    display flex
    justify-content center
    margin-top 15px
    margin-bottom 15px
    .Image
      box(190px)
      margin 0 7.5px
      border 1px solid $color_grey_lightest

  .ExplanationWrap,
  .WorkDescriptionWrap
    padding 15px 20px
    border-bottom 1px solid $color_grey_lighter
    p
      &:last-child
        padding-bottom 0

  .MoreOnNextPage
    padding 10px 20px
</style>
