<template>
  <div
    :id="getSampleID()"
    class="SampleTableColumn">
    <!-- Header -->
    <div
      class="Header"
      :class="sample.assessNumber ? assessedTestResults.overallClass : testResults.overallClass">
      <span class="Type">{{ getSampleTitle() }}</span>
      <span
        v-if="sample.kindOfSample === 'coating'"
        class="Kind">
        {{ mixWB('COATING') }}
      </span>
      <span
        v-if="sample.kindOfSample === 'underlining'"
        class="Kind">
        {{ mixWB('UNDERLINING') }}
      </span>
    </div>

    <!-- Test results - Normal and equivalent -->
    <template v-if="sample.sampleNumber || sample.equivalentSampleNumber">
      <div
        v-for="item in testResults.items"
        :key="item.id"
        :class="item.styleClass">
        <span v-if="item.text">{{ item.text }}</span>
      </div>
    </template>

    <!-- Test results - Assessed -->
    <template v-if="sample.assessNumber">
      <div
        v-for="item in assessedTestResults.items"
        :key="item.id"
        :class="item.styleClass">
        <span>{{ item.text }}</span>
      </div>
    </template>

    <!-- Image -->
    <div
      v-if="sample.sampleNumber && !hideImage"
      class="ImageWrap">
      <div class="Inner">
        <MultiImage :image="sample.images.close"  />
      </div>
    </div>
  </div>
</template>

<script>
import { testResultsForSingleSample } from '@/globals/javascript/_util/test-results'
import { checkToAddPCBToType } from '@/globals/javascript/_util/samples'
import MultiImage from '@/components/Images/MultiImage.vue'
import { mapGetters } from 'vuex'
import { union } from 'lodash-es'

export default {
  name: 'SampleTableColumn',
  props: {
    sample: {
      type: Object,
      required: true,
    },
    hideImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningTestResults',
      'screeningPCBScreenings',
      'screeningRelatedScreenings',
    ]),
    testResult() {
      // Get test result from related screening
      if (this.sample.otherScreeningID) {
        const relatedScreening = this.screeningRelatedScreenings.find(
          (x) => x.data.id === this.sample.otherScreeningID,
        )
        return relatedScreening?.testResults?.find(
          (x) => x.sampleNumber === this.sample.equivalentSampleNumber,
        )
      }

      // Get test result from this screening
      if (!this.screeningTestResults) {
        return false
      }
      return this.screeningTestResults.find(
        (x) => x.sampleNumber === this.sample.sampleNumber
        || x.sampleNumber === this.sample.equivalentSampleNumber,
      )
    },
    type() {
      return this.screeningTypes.find((x) => x.id === this.sample.typeID)
    },
    testResults() {
      if (!this.testResult) {
        return {
          overallClass: '',
          items: [],
        }
      }
      const cpScreeningTest = this.testResult.results.find((x) => x.id === 'cpScreening')
      const result = testResultsForSingleSample.reduce((prev, item) => {
        const test = this.testResult.results.find((x) => x.id === item.id)
        const tableItem = {
          ...test,
          styleClass: 'ItemNotAnalysed',
          wordbook: item.wordbook,
          text: '',
        }

        // Set text
        if (test.isTooLowToMeasure) {
          tableItem.text = this.mixWB('BELOW_THE_LIMIT')
        }
        else if (!test.value) {
          tableItem.text = this.mixWB('NOT_ANALYZED_SHORT')
        }
        else {
          tableItem.text = `${
            test.isBelowValue ? decodeURI('%3C') : ''
          } ${
            test.isAboveValue ? decodeURI('%3E') : ''
          } ${
            this.mixFormatNumber(test.value)
          } ${ test.unit || '' }`
        }

        // CP special cases
        if (['cpShort', 'cpMedium'].includes(test.id)) {
          if (cpScreeningTest.value === 'Ikke påvist') {
            tableItem.text = this.mixWB('W: Ikke påvist')
            tableItem.result = 0
          }
          if (this.testResult.cpScreeningAutoProven) {
            tableItem.text = this.mixWB('W: Påvist')
            tableItem.result = 2
          }
        }

        // Set item class
        if (tableItem.result === 0) {
          tableItem.styleClass = 'ItemClean'
        }
        if (tableItem.result === 1) {
          tableItem.styleClass = 'ItemContaminated'
        }
        if (tableItem.result === 2) {
          tableItem.styleClass = 'ItemHazardousWaste'
        }

        // Update overall result
        if (tableItem.result > prev.highestResult) {
          prev.highestResult = tableItem.result
        }

        prev.items.push(tableItem)
        return prev
      }, {
        highestResult: 0,
        items: [],
      })

      let overallClass = ''
      if (result.highestResult === 0) {
        overallClass = 'ItemClean'
      }
      if (result.highestResult === 1) {
        overallClass = 'ItemContaminated'
      }
      if (result.highestResult === 2) {
        overallClass = 'ItemHazardousWaste'
      }

      return {
        overallClass,
        items: result.items,
      }
    },
    assessedTestResults() {
      let allSampleIDs = []
      if (this.sample.kindOfSample === 'material') {
        allSampleIDs = union(this.type.samples.sampleIDs, this.type.assessments.assessmentIDs)
      }
      if (this.sample.kindOfSample === 'coating') {
        allSampleIDs = this.type.coating.samples.sampleIDs
      }
      if (this.sample.kindOfSample === 'underlining') {
        allSampleIDs = this.type.underlining.samples.sampleIDs
      }

      const addPCBAnalysis = checkToAddPCBToType(
        this.type,
        this.screeningPCBScreenings,
        this.sample.kindOfSample,
      )

      const result = testResultsForSingleSample.reduce((prev, item) => {
        const tableItem = {
          styleClass: 'ItemNotAnalysed',
          text: '',
        }

        if (item.id === 'PCB') {
          if (allSampleIDs.includes('ST-4') || addPCBAnalysis) {
            tableItem.styleClass = 'ItemHazardousWaste'
            tableItem.text = this.mixWB('ASSESSED')
          }
          else {
            tableItem.text = this.mixWB('NOT_ANALYZED_SHORT')
          }
        }
        else if (item.id === 'PAH') {
          if (allSampleIDs.includes('ST-3')) {
            tableItem.styleClass = 'ItemHazardousWaste'
            tableItem.text = this.mixWB('ASSESSED')
          }
          else {
            tableItem.text = this.mixWB('NOT_ANALYZED_SHORT')
          }
        }
        else if (item.id === 'ASB') {
          if (allSampleIDs.includes('ST-1')) {
            tableItem.styleClass = 'ItemHazardousWaste'
            tableItem.text = this.mixWB('ASSESSED')
          }
          else {
            tableItem.text = this.mixWB('NOT_ANALYZED_SHORT')
          }
        }
        else if (allSampleIDs.includes('ST-2') || allSampleIDs.includes('ST-5')) {
          tableItem.styleClass = 'ItemHazardousWaste'
          tableItem.text = this.mixWB('ASSESSED')
        }
        else {
          tableItem.text = this.mixWB('NOT_ANALYZED_SHORT')
        }

        prev.items.push(tableItem)
        return prev
      }, {
        overallClass: 'ItemHazardousWaste',
        items: [],
      })

      return result
    },
  },
  methods: {
    getSampleID() {
      if (this.sample.sampleNumber) {
        return `sample-p${ this.sample.sampleNumber }`
      }
      if (this.sample.assessNumber) {
        return `sample-v${ this.sample.assessNumber }`
      }
      return ''
    },
    getSampleTitle() {
      let title = ''
      if (this.sample.equivalentNumber) {
        title += `T${ this.sample.equivalentNumber } (P${ this.sample.equivalentSampleNumber })`
      }
      if (this.sample.sampleNumber) {
        title += `P${ this.sample.sampleNumber }`
      }
      if (this.sample.assessNumber) {
        title += `V${ this.sample.assessNumber }`
      }
      title += ` - ${ this.type.getTitle() }`
      return title
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .SampleTableColumn
    width 25%
    border-right 1px solid $color_grey_lighter
    &:nth-child(4)
      border-right none
    > div
      background-color #fff
      border-bottom 1px solid #fff
      &:nth-child(even)
        background-color $color_grey_lightest
        border-bottom 1px solid $color_grey_lightest
      &.ItemClean
        background-color $color_report_green
        border-bottom 1px solid $color_report_green_alt
      &.ItemContaminated
        background-color $color_report_yellow
        border-bottom 1px solid $color_report_yellow_alt
      &.ItemHazardousWaste
        background-color $color_report_red
        border-bottom 1px solid $color_report_red_alt
    span
      padding 2.5px 10px
      font-size 0.75rem
    .Header
      height 40px
      display flex
      flex-direction column
      justify-content center
      background-color transparent
      border-bottom 1px solid transparent
      padding 0px 10px
      span
        truncated()
        width 100%
        padding 0
        line-height 1.25
      .Type
        font-size 0.875rem
      .Kind
        font-size 0.75rem

  .ImageWrap
    display flex
    align-items center
    justify-content center
    padding 5px 0px 4px
    .Inner
      box(120px)

</style>
