<template>
  <div class="CategoryTypePage">
    <!-- First type of a category -->
    <template v-if="isFirstItem">
      <CategoryOverviewPage
        v-for="(startIndex, index) in pageStartIndexes"
        :key="startIndex"
        :startIndex="startIndex"
        :isFirstPage="index === 0"
        :isLastPage="isInitialLoadComplete && index === pageStartIndexes.length - 1"
        :category="category"
        :categoryIndex="categoryIndex"
        :groupedType="groupedType"
        :previousGroupedTypes="previousGroupedTypes"
        :nextGroupedTypes="nextGroupedTypes"
        :filteredGroupedTypeSamples="getFilteredGroupedTypeSamples()"
        :hasNoRoomForWorkDescription="hasNoRoomForWorkDescription"
        :splitIntoTwoPages="splitIntoTwoPages"
        @page-is-full="onPageIsFull"
        @last-item-added="onLastItemAdded"
        @split-into-two-pages="onSplitIntoTwoPages" />
    </template>
    <!-- Rest of types -->
    <div
      v-else
      class="Page">
      <div
        class="InnerPage">
        <CategoryType
          ref="CategoryTypeMainPage"
          :groupedType="groupedType"
          :previousGroupedTypes="previousGroupedTypes"
          :nextGroupedTypes="nextGroupedTypes"
          :filteredGroupedTypeSamples="getFilteredGroupedTypeSamples()"
          :categoryIndex="categoryIndex"
          :hasNoRoomForWorkDescription="hasNoRoomForWorkDescription" />
      </div>

      <Footer />
    </div>

    <!-- Type - New page -->
    <div
      v-if="splitIntoTwoPages"
      class="Page">
      <div class="InnerPage">
        <CategoryType
          ref="CategoryTypeOwnPage"
          :groupedType="groupedType"
          :previousGroupedTypes="previousGroupedTypes"
          :nextGroupedTypes="nextGroupedTypes"
          :filteredGroupedTypeSamples="getFilteredGroupedTypeSamples()"
          :categoryIndex="categoryIndex"
          :hasNoRoomForWorkDescription="hasNoRoomForWorkDescription" />
      </div>

      <Footer />
    </div>

    <!-- Show extra page -->
    <div
      v-if="hasNoRoomForWorkDescription"
      class="Page">
      <div
        class="InnerPage">
        <CategoryTypeExtraPage
          :groupedType="groupedType"
          :previousGroupedTypes="previousGroupedTypes"
          :nextGroupedTypes="nextGroupedTypes"
          :filteredGroupedTypeSamples="getFilteredGroupedTypeSamples()" />
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import CategoryOverviewPage from '@/components/PDF/CategoriesAndTypes/CategoryOverviewPage.vue'
import CategoryTypeExtraPage from '@/components/PDF/CategoriesAndTypes/CategoryTypeExtraPage.vue'
import CategoryType from '@/components/PDF/CategoriesAndTypes/CategoryType.vue'
import Footer from '@/components/PDF/Footer.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'CategoryTypePage',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
    previousGroupedTypes: {
      type: Array,
      required: true,
    },
    nextGroupedTypes: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    isFirstItem: {
      type: Boolean,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      maxPageHeight: 950,
      noRoomForWorkDescription: false,
      splitIntoTwoPages: false,
      isInitialLoadComplete: false,
      pageStartIndexes: [0],
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
    ]),
    hasNoRoomForWorkDescription() {
      if (this.getFilteredGroupedTypeSamples().length > 3) {
        return true
      }

      if (this.noRoomForWorkDescription) {
        return true
      }

      return false
    },
  },
  methods: {
    getFilteredGroupedTypeSamples() {
      let samples = []
      this.groupedType.types.forEach((type) => {
        const typeSamples = []
        this.screeningSamples.forEach((sample) => {
          if (sample.typeID === type.id) {
            if (sample.kindOfSample === 'material') {
              typeSamples.unshift(sample)
            }
            if (sample.kindOfSample === 'coating') {
              typeSamples.push(sample)
            }
            if (sample.kindOfSample === 'underlining') {
              typeSamples.push(sample)
            }
          }
        })
        samples = samples.concat(typeSamples)
      })
      return samples
    },
    onPageIsFull(nextGroupedTypeIndex) {
      this.pageStartIndexes.push(nextGroupedTypeIndex)
    },
    onLastItemAdded() {
      if (!this.isInitialLoadComplete) {
        EventBus.$emit('category-overview-loaded', this.category.id)
        this.isInitialLoadComplete = true
      }
      else {
        EventBus.$emit('page-updated')
      }
    },
    onSplitIntoTwoPages() {
      this.splitIntoTwoPages = true
      this.checkCategoryTypeHeight()
    },
    checkCategoryTypeHeight() {
      // First type
      if (this.isFirstItem && this.splitIntoTwoPages) {
        if (!this.$refs.CategoryTypeOwnPage || !this.$refs.CategoryTypeOwnPage.$refs.CategoryType) {
          setTimeout(() => {
            this.checkCategoryTypeHeight()
          }, 100)
          return
        }

        const { height } = this.$refs.CategoryTypeOwnPage.$refs.CategoryType.getBoundingClientRect()
        if (height <= this.maxPageHeight) {
          return
        }
        this.noRoomForWorkDescription = true
        return
      }

      // Rest of types
      if (!this.$refs.CategoryTypeMainPage || !this.$refs.CategoryTypeMainPage.$refs.CategoryType) {
        setTimeout(() => {
          this.checkCategoryTypeHeight()
        }, 100)
        return
      }

      const { height } = this.$refs.CategoryTypeMainPage.$refs.CategoryType.getBoundingClientRect()
      if (height <= this.maxPageHeight) {
        return
      }
      this.noRoomForWorkDescription = true
    },
  },
  components: {
    CategoryOverviewPage,
    CategoryType,
    CategoryTypeExtraPage,
    Footer,
  },
  mounted() {
    if (!this.isFirstItem) {
      const waitForContent = setInterval(() => {
        if (!this.$refs.CategoryTypeMainPage?.$refs.CategoryType) {
          return
        }

        clearInterval(waitForContent)
        this.checkCategoryTypeHeight()
      }, 100)
    }
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypePage
    display block

  .Page
    page()

  .InnerPage
    innerPageReport()
</style>
