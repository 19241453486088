<template>
  <div class="AnalysisResults">
    <AnalysisResultsPage
      v-for="(startIndex, index) in pageStartIndexes"
      :key="startIndex"
      :startIndex="startIndex"
      :isFirstPage="index === 0"
      :samples="sortedSamples"
      :pdfType="pdfType"
      @page-is-full="onPageIsFull"
      @all-samples-added="onAllSamplesAdded" />
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import AnalysisResultsPage from '@/components/PDF/AnalysisResults/AnalysisResultsPage.vue'
import EventBus from '@/EventBus'

export default {
  name: 'AnalysisResults',
  props: {
    pdfType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isInitialLoadComplete: false,
      pageStartIndexes: [0],
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
      'screeningTestResults',
    ]),
    sortedSamples() {
      if (this.pdfType !== 'tender') {
        return sortBy(this.screeningSamples, ['sampleNumber', 'equivalentNumber', 'assessNumber'])
      }

      if (!this.screeningTestResults) {
        return []
      }

      // Only return contaminated samples
      return sortBy(this.screeningSamples.filter((sample) => {
        if (sample.assessNumber) {
          return true
        }

        let testResult = null
        if (sample.equivalentNumber) {
          testResult = this.screeningTestResults.find(
            (x) => x.sampleNumber === sample.equivalentSampleNumber,
          )
        }
        if (sample.sampleNumber) {
          testResult = this.screeningTestResults.find(
            (x) => x.sampleNumber === sample.sampleNumber,
          )
        }

        if (!testResult) {
          return false
        }

        if (
          testResult.overallResult > 0
          || testResult.metalsAboveThreshold
          || testResult.cpScreeningAutoProven
        ) {
          return true
        }

        return false
      }), ['sampleNumber', 'equivalentNumber', 'assessNumber'])
    },
  },
  watch: {
    screeningSamples() {
      this.onResetPages()
    },
  },
  methods: {
    onPageIsFull(nextSampleIndex) {
      this.pageStartIndexes.push(nextSampleIndex)
    },
    onAllSamplesAdded() {
      if (!this.isInitialLoadComplete) {
        EventBus.$emit('page-loaded', 'analysisOverview')
        this.isInitialLoadComplete = true
      }
      else {
        EventBus.$emit('page-updated')
      }
    },
    onResetPages() {
      this.pageStartIndexes = []
      requestAnimationFrame(() => {
        this.pageStartIndexes = [0]
      })
    },
  },
  components: {
    AnalysisResultsPage,
  },
}
</script>

<style lang="stylus" scoped>
  .AnalysisResults
    display block
</style>
