<template>
  <div class="BackOptions">
    <span>{{ mixWB('BACK_TO') }}:</span>
    <template v-for="option in options">
      <a
        v-if="option.show"
        :key="option.id"
        :href="'#' + option.id"
        @click.prevent="onBackClick(option.id)">{{ option.text }}</a>
    </template>
  </div>
</template>

<script>
import { scrollTo } from '@/globals/javascript/_util/util'
import { mapGetters } from 'vuex'

export default {
  name: 'BackOptions',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentRoute',
    ]),
    options() {
      return [
        {
          id: `category-${ this.groupedType.categoryIndex }`,
          text: this.mixWB('THIS_CATEGORY'),
          show: true,
        },
        {
          id: 'assessment-and-recommendation',
          text: this.mixWB('ALL_CATEGORIES'),
          show: this.currentRoute?.meta?.siteArea !== 'tender',
        },
        {
          id: 'table-of-samples',
          text: this.mixWB('ANALYSIS_RESULTS'),
          show: true,
        },
        {
          id: 'floor-plans',
          text: this.mixWB('FLOOR_PLANS'),
          show: this.currentRoute?.meta?.siteArea !== 'tender',
        },
      ]
    },
  },
  methods: {
    onBackClick(id) {
      const element = document.getElementById(id)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .BackOptions
    position absolute
    bottom -30px
    left 0
    display flex
    cursor pointer
    z-index 1
    span
      padding-right 5px
    a
      text-decoration underline
      position relative
      margin-right 12px
      &:after
        content '-'
        text-decoration none
        position absolute
        top 1px
        right -8px
      &:last-child
        &:after
          display none
</style>
