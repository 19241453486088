<template>
  <div
    class="CategoriesAndTypes">
    <template v-for="categoryItem in categoriesWithGroupedTypes">
      <CategorySection
        v-if="categoryItem.groupedTypes.length"
        :key="categoryItem.id"
        :categoryItem="categoryItem"
        :categoryIndex="categoryItem.index"
        :isTender="pdfType === 'tender'"
        @page-initialized="onPageInitialized" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import CategorySection from '@/components/PDF/CategoriesAndTypes/CategorySection.vue'

export default {
  name: 'CategoriesAndTypes',
  props: {
    pdfType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      donePages: {},
    }
  },
  computed: {
    ...mapGetters([
      'categoriesWithGroupedTypes',
    ]),
  },
  methods: {
    onPageInitialized(categoryID) {
      this.donePages[categoryID] = false
    },
    onCategoryOverviewLoaded(categoryID) {
      this.donePages[categoryID] = true

      const allDone = Object.keys(this.donePages).every((key) => this.donePages[key])
      if (allDone) {
        EventBus.$emit('page-loaded', 'categoriesAndTypes')
      }
    },
  },
  components: {
    CategorySection,
  },
  created() {
    EventBus.$on('category-overview-loaded', this.onCategoryOverviewLoaded)
  },
  destroyed() {
    EventBus.$off('category-overview-loaded', this.onCategoryOverviewLoaded)
  },
}
</script>

<style lang="stylus" scoped>
  .CategoriesAndTypes
    display block
</style>
