<template>
  <div
    :id="'category-' + categoryIndex"
    class="CategoryOverview">
    <div class="TableWrap">
      <!-- Legend -->
      <div class="Row Legend">
        <span class="ID">{{ mixWB('ID') }}</span>
        <span
          v-if="selectedUnits.length"
          class="Unit">{{ mixWB('UNITS') }}
        </span>
        <span
          v-else
          class="Building">{{ mixWB('BUILDINGS') }}
        </span>
        <span class="Type">{{ mixWB('TYPE') }}</span>
        <span class="Note">{{ mixWB('NOTE') }}</span>
        <span class="Risk">{{ mixWB('RISK_FOR') }}</span>
      </div>

      <!-- Types -->
      <div
        class="Row"
        v-for="groupedType in groupedTypes"
        :key="groupedType.id">
        <span class="ID">{{ categoryIndex }}.{{ groupedType.index }}</span>
        <span
          v-if="groupedType.types[0].unitIDs.length"
          class="Unit">
          <span
            v-for="unit in groupedType.types[0].getUnitsList().split(',')"
            :key="unit">{{ unit }}</span>
        </span>
        <span
          v-else
          class="Building">
          {{ getTypeBuildingNumbers(groupedType.types[0]) }}
        </span>
        <div class="Type">
          <a
            :href="'#' + groupedType.id"
            @click.prevent="onConnectionClick(groupedType.id)">
            {{ groupedType.getGroupedTypeTitle(
              {
                withDetails: true,
                withIndex: true,
              }
            ) }}</a>
          <a
            :href="'#' + item.id"
            v-for="item in getConnectedText(groupedType)"
            :key="item.id"
            class="ConnectedText"
            v-html="item.text"
            @click.prevent="onConnectionClick(item.id)"/>
        </div>
        <span class="Note">{{ getGroupedTypeDescription(groupedType) }}</span>
        <span class="Risk">{{ getGroupedTypeRisks(groupedType) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy, union } from 'lodash-es'
import { mapGetters } from 'vuex'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'CategoryOverview',
  props: {
    category: {
      type: Object,
      required: true,
    },
    groupedTypes: {
      type: Array,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'selectedBuildings',
      'sampleTypesAsArray',
    ]),
  },
  methods: {
    getTypeBuildingNumbers(type) {
      if (!this.selectedBuildings) {
        return ''
      }

      const sortedList = sortBy(type.buildings)
      const numbers = sortedList.reduce((prev, buildingID) => {
        const building = this.selectedBuildings.find((x) => x.buildingID === buildingID)
        prev.push(building.data.Bygningsnr)
        return prev
      }, [])

      return numbers.join(', ')
    },
    getConnectedText(groupedType) {
      return groupedType.previousGroupedTypes.map((previousGroupedType) => ({
        id: previousGroupedType.id,
        text: `${
          this.mixWB('SITS_ON_X', [
            previousGroupedType.getGroupedTypeTitle({
              withDetails: true,
              withIndex: true,
            }),
          ])
        }`,
      }))
    },
    getGroupedTypeDescription(groupedType) {
      const descriptions = []
      groupedType.types.forEach((type) => {
        if (type.description) {
          let text = type.description
          if (text.slice(-1) !== '.') {
            text += '.'
          }
          descriptions.push(text)
        }
      })

      return descriptions.length ? descriptions.join(' ') : '-'
    },
    getGroupedTypeRisks(groupedType) {
      let allIDs = []
      groupedType.types.forEach((type) => {
        allIDs = union(allIDs, type.getAllSampleAndAssessmentIDs())
      })

      let names = ''

      allIDs.forEach((assessmentIDs, index) => {
        const sample = this.sampleTypesAsArray.find((x) => x.id === assessmentIDs)
        if (index === 0) {
          names = this.mixWB(sample.wordbook)
          return
        }
        names += `, ${ this.mixWB(sample.wordbook) }`
      })
      return names || '-'
    },
    onConnectionClick(id) {
      const element = document.getElementById(id)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryOverview
    border 1px solid $color_grey_dark
    margin-bottom 20px

  .Row
    display flex
    align-items center
    > span
      padding 5px 10px
    > div
      padding 2.5px 10px
    &.Legend
      background-color $color_grey_dark
      span
        color #fff
        padding 10px
    .Unit
      span
        display inline-block
        &:not(:last-child):after
          content: ','
          margin-right: 2px;
    &.Connected
      span
        font-style italic
    &:nth-child(even)
      background-color $color_grey_lightest

  .ID
    width 50px
    flex-shrink 0
  .Unit,
  .Building
    width 150px
    flex-shrink 0
  .Type
    width 200px
    flex-shrink 0
    .ConnectedText
      padding-top 0
      color $color_grey
      font-style italic
      >>> a
        color $color_grey
    >>> a
      text-decoration underline
      display block

  .Note
    flex-grow 2
  .Risk
    width 150px
    flex-shrink 0
</style>
