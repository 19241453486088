import { render, staticRenderFns } from "./CategoriesAndTypes.vue?vue&type=template&id=3a995746&scoped=true&"
import script from "./CategoriesAndTypes.vue?vue&type=script&lang=js&"
export * from "./CategoriesAndTypes.vue?vue&type=script&lang=js&"
import style0 from "./CategoriesAndTypes.vue?vue&type=style&index=0&id=3a995746&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a995746",
  null
  
)

export default component.exports