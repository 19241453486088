<template>
  <div class="CategorySection">
    <!-- Categories with types -->
    <CategoryTypePage
      v-for="(groupedType, index) in categoryItem.groupedTypes"
      :key="groupedType.id"
      :groupedType="groupedType"
      :previousGroupedTypes="groupedType.previousGroupedTypes"
      :nextGroupedTypes="groupedType.nextGroupedTypes"
      :category="categoryItem.category"
      :categoryIndex="categoryIndex"
      :isFirstItem="index === 0"
      :isTender="isTender" />

  </div>
</template>

<script>
import CategoryTypePage from '@/components/PDF/CategoriesAndTypes/CategoryTypePage.vue'

export default {
  name: 'CategorySection',
  props: {
    categoryItem: {
      type: Object,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    CategoryTypePage,
  },
  created() {
    if (this.categoryItem.groupedTypes.length) {
      this.$emit('page-initialized', this.categoryItem.category.id)
    }
  },
}
</script>

<style lang="stylus" scoped>
  .CategorySection
    display block
</style>
